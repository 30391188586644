<template>
  <v-main :anchor-layout="layoutName" v-if="appReady" class="linerp-layout">
    <liff-header v-model="showSidebar"></liff-header>
      
    <div>
      <section v-if="layoutReady">
        <router-view v-if="showPage"></router-view>

        <section class="">
          <!-- 須認證頁面且LINE未登入(非LIFF模式開啟) -->
          <div v-if="!isLogin" class="d-flex flex-column align-center justify-center py-10">
            <div class="mb-6">
              <v-img src="~@/assets/linerp_logo_g.svg" width="230"> 
              </v-img>
            </div>
            <div class="mb-4">
              <v-btn
                @click="lineLogin"
                elevation="0"
                class="primary white--text"
              >
                {{ "action.login_via_line" | t }}
              </v-btn>
            </div>

            <div v-if="false" class="my-4 red--text">
              {{ loginError }}
            </div>
            
          </div>
        </section>


      </section>
    </div>
  </v-main>
</template>

<script lang="babel" type="text/babel">
import layoutMixins from '@/layout/layoutMixins'
import liff from '@line/liff'
import {liffClientId} from "@/config/env.json";
import dayjs from "dayjs"
import Announce from '@/layout/announce.vue'
import Vue from "vue"

export default {
  mixins: [layoutMixins],
  component:{
    Announce
  },
  data: () => ({
    layoutName: 'member',
    isLiffLoggedIn: false,
    idToken: null,
    accessToken: null,
    appReady: false,
    loginError: null,
  }),
  created() {
    // alert(`進入linerp layout: ${this.$route.fullPath}`)
    this.init()
  },
  methods: {
    async setupLiff() {
      // alert(`linerp layout liff.init: 使用 ${liffClientId}`)
      await window.liff.init({
        liffId: liffClientId,
      })
      await this.$helper.delay(0.2)
      this.isLiffLoggedIn = window.liff.isLoggedIn()
      this.idToken = window.liff.getIDToken()
      this.accessToken = window.liff.getAccessToken()
    },
    async init() {
      this.appReady = false
      if(this.isLogin) {
        await this.readMemberProfile()
        await this.$nextTick()
      }
      
      await this.setupLiff()
      await this.$nextTick()
      // 由客戶角度查看供應商的角度來的, 必須清除token重新登入 (應該只有開發階段會發生)
      if(this.shouldClearToken) {
        window.tokenStore.clean()
        this.$store.dispatch('member/clean')
        this.$snotify.warning('畫面即將重新整理', '身份切換中')
        await this.$helper.delay(1)
        window.location.reload()
        return
      }

      // alert('isLiffLoggedIn:' + this.isLiffLoggedIn );
      // alert('isLogin:' + this.isLogin);
      if(this.isLiffLoggedIn && !this.isLogin) {
        // alert('執行liffLogin')
        this.liffLogin()
        console.warn('liffLogin')
      }
      
      await this.$nextTick()
      await this.$helper.delay(1)
      // 讀取供應商資料
      if(this.isLogin) {
        await this.readProvider()
        await this.checkProviders()
        await this.showAnnounce()
      }
      
      this.appReady = true
    },
    async readProvider() {
      console.log('readProvider')
      if(!this.providerId) return
      this.$store.dispatch('loading/active')
      try {
        const res = await this.$api.collection.providerApi.read(this.providerId)
        this.$store.dispatch('base/setLayoutProvider', res)
      } catch(err) {

      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async readAnnounce() {
      try{
        const announce = await this.$api.collection.providerApi.getProviderAnnounce();
        return announce;  
      }catch(e){
        console.log(e)
      }
    },
    async showAnnounce(){
      try{
        const announce = await this.readAnnounce();
        const announceContent = announce.find((e)=>e.type==="ADMIN_PROVIDER")
        const disableAnnounce = localStorage.getItem('disable_announce');
        if(announce && announce.length > 0 && announceContent && (!disableAnnounce || dayjs(disableAnnounce).endOf('day').valueOf() < dayjs().valueOf())){
          this.$apopup.base({
            title: "系統公告",
            content: announceContent.content,
            close: false,
            actionSlot: Announce,
            applyCallback: () => null,
            
          })
        }
      }catch(e){}
      
    },
    /**
     * 一般line login
     * 在非liff模式下可使用此方式登入會員
     */
    async lineLogin() {
      this.$root.gaLogEvent('供應商_點擊_登入')
      const url = this.$router.resolve({name: 'provider-switch-identity'}).href
      console.log('lineLogin:', url)
      this.$oauth.login({
        provider: 'line',
        clientKey: this.oauthClient.line,
        successPath: url,
        keepLogin: true,
        isDebugMode: true,
      })
    },
    /**
     * 透過liff登入(使用id token + access token)
     */
    async liffLogin() {
      let params = {
        credential: this.accessToken,
        keep_login: true,
        id_token: this.idToken,
        redirectUri: '/identity/switch',
      }
      try {
        const result = await this.$api.collection.baseApi.lineOauthLogin(params)
        this.$tokenStore.set(result.token)
        this.$helper.account.login(this.rememberEmail)
        this.$store.dispatch(`member/set`, result.profile)
      } catch (error) {
        console.error(error)
        this.loginError = error
      } finally {
      }
    },
    checkProviders() {
      if (this.routeName === 'provider-switch-identity') {
        return
      }
      
      if (!this.hasProvider) {
        this.$snotify.success('請先註冊供應商', '您尚未註冊供應商')
        this.$router.replace({ name: 'provider-register' })
        return
      }
    },
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    version() {
      return version
    },
    isPublicRoute() {
      return this.$route.meta.public
    },
    showPage() {
      if(this.isPublicRoute) return true
      return this.isLogin === true
    },
    redirectPath() {
      return this.$route.query.path || '/'
    },
    hasProvider() {
      return this.$store.getters['member/hasProvider']
    },
    providersQuantity() {
      return this.memberProviders.length
    },
    memberProviders() {
      return this.$store.getters['member/memberProviders']
    },
    isLogin() {
      return this.$store.getters['token/isLogin']
    },
    oauthClient() {
      return this.$store.getters['base/oauthClient']
    },
    tokenRole() {
      return this.$store.getters['token/tokenRole']
    },
    shouldClearToken() {
      if(!this.isLogin) return false
      if(this.tokenRole != this.$tokenRoleConstant.ROLE_PROVIDER) return true // token身份不是provider
      return false
    },
  },
}
</script>

